<template>
  <v-dialog @click:outside="$emit('closePackageDialog')" v-model="dialogPacket" max-width="500" scrollable>
    <v-card>
      <v-card-title v-if="dialogPacketType === 'info'" class="text-h5">Інфо пакету</v-card-title>
      <v-card-title v-else-if="dialogPacketType === 'error'" class="text-h5">Помилки сервера</v-card-title>
      <v-card-text>
        <pre v-if="dialogPacketType === 'info' && selectedPacket" style="font-size: 14px">
          {{ selectedPacket }}
        </pre>
        <code v-else-if="dialogPacketType === 'error' && selectedPacket" style="font-size: 14px; color: #ff5252">
          {{ selectedPacket }}
        </code>
        <span v-else>Немає даних для відображення</span>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('closePackageDialog')"> закрити </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LogsPackageModal',

  props: {
    dialogPacket: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogPacketType: {
      type: String,
      required: true,
      default: '',
    },
    selectedPacket: {
      required: true,
      default: null,
    },
  },
}
</script>

<style scoped></style>
