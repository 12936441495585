import { render, staticRenderFns } from "./LogsPackageModal.vue?vue&type=template&id=0cd486f6&scoped=true"
import script from "./LogsPackageModal.vue?vue&type=script&lang=js"
export * from "./LogsPackageModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd486f6",
  null
  
)

export default component.exports